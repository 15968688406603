// TeaVaultV2 Wallet Client
// Teahouse Finance

import { TeaVaultV2Wallet } from "./teavaultv2wallet";
import { ethers } from "ethers";
import TeaVaultV2 from "./abi/TeaVaultV2.json";
import IERC20 from './abi/IERC20.json';

// use /<vault addr> in the URI instead
// e.g. http://localhost:8080/0x1234567890
// /?vault=<vault addr> is also supported but deprecated
//const DEFAULT_VAULT_ADDRESS = '0x3E88F09A1F005B90ab3D0a500780F14fED040f1d';
const DEFAULT_VAULT_ADDRESS = '0x18b582f52f52343D644e05c409f7E129614E4E1a';

let teavaultWallet = null;
let vaultAddress = false;

function showInstallMetaMask() {
    const element = document.getElementById('content');
    element.innerHTML = '<b>MetaMask is not installed.</b>';
}

function showConnectWallet() {
    const element = document.getElementById('content');
    element.innerHTML = '<button type="button" onclick="connectWallet()">CONNECT WALLET</button>';
}

function showNoVaultAddress() {
    const element = document.getElementById('content');
    element.innerHTML = '<b>No TeaVaultV2 address specified.';
}


async function showWalletInfo() {
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length == 0) {
        showConnectWallet();
        return;
    }

    const address = accounts[0];

    let content = '<button type="button" onclick="disconnectWallet()">DISCONNECT</button><br>';
    content += 'Address: ' + address + '<br>';   
    content += '<p>';

    if (!(await teavaultWallet.checkVault())) {
        content += "Not correct vault address or incorrect network<br>";
    }
    else {
        // show ETH balance etc.
        content += '<p>';
        const vaultAddress = teavaultWallet.getVaultAddress();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balance = await provider.getBalance(vaultAddress);
        content += 'ETH balance: ' + ethers.utils.formatEther(balance) + '<br>';
        content += 'Amount: <input type="text" id="ethamount"> To: <input type="text" id="ethaddr"> <button type="button" id="sendEth" onclick="sendETH()">SEND ETH</button><br>';
        content += '<input type="text" id="erc20addr"><button type="button" onclick="showERC20Balance()">SHOW ERC20 BALANCE</button><br>';
        content += '<div id="erc20balance"></div>';

        const config = await teavaultWallet.getVaultConfig();

        if (config.manager.toLowerCase() != address.toLowerCase()) {
            content += "Not connected to manager's address<br>";
        }
        else {
            // show manager wallet info
            
            if (teavaultWallet.hasSession()) {
                // already has seesion
                await teavaultWallet.linkWalletConnect();
            }
    
            if (teavaultWallet.walletConnectLinked()) {
                content += 'WalletConnect linked.<br>';
            }
            else {
                content += 'Connected to manager\'s address<br>';
                content += '<input type="text" id="walletConnectURI"><button type="button" onclick="linkWalletConnect()" id="linkWallet">LINK WALLET CONNECT</button><br>';
            }    
        }

        content += '<p>';

        if (config.investor.toLowerCase() == address.toLowerCase()) {
            content += "Connected to investor's address<br>";

            // show investor wallet info
            content += 'ETH amount: <input type="text" id="depositethamount"><br>';
            content += '<button type="button" onclick="depositEth()" id="depositEth">DEPOSIT ETH</button>';
            content += '<button type="button" onclick="withdrawEth()" id="withdrawEth">WITHDRAW ETH</button><br>';

            content += 'ERC20 address: <input type="text" id="depositerc20addr">';
            content += 'ERC20 amount: <input type="text" id="depositerc20amount"><br>';
            content += '<button type="button" onclick="approveErc20()" id="approveErc20">APPROVE ERC20</button>';
            content += '<button type="button" onclick="depositErc20()" id="depositErc20">DEPOSIT ERC20</button>';
            content += '<button type="button" onclick="withdrawErc20()" id="withdrawErc20">WITHDRAW ERC20</button><br>';
        }
    }

    const element = document.getElementById('content');
    element.innerHTML = content;
}

async function callback(params) {
    console.log("callback: ", params);

    switch(params.event) {
        case 'session_connected':
        case 'session_updated':
        case 'session_disconnected':
            await showWalletInfo();
            break;

        case 'error':
            appendMessage('Error: ' + params.error.message);
            break;

        case 'result':
            if (params.method == 'eth_sendTransaction') {
                appendMessage("Sent tx: " + params.results);
            }
            break;

        default:
            break;
    }
}

function appendMessage(message) {
    let msg = document.getElementById('messages').innerHTML;
    msg += '<br>' + message;
    document.getElementById('messages').innerHTML = msg;
}

function getVaultAddress() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    if (url.pathname == "/") {
        // fallback to /?vault=<vault address>
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (!urlParams.has('vault')) {
            return DEFAULT_VAULT_ADDRESS;
        }
    
        return urlParams.get('vault');
    }
    else {
        const addr = url.pathname.slice(1);
        if (ethers.utils.isAddress(addr)) {
            return addr;
        }
        else {
            return '';
        }
    }
}

// connect wallet
window.connectWallet = async function() {
    try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        // refresh content
        await showContent();
    }
    catch(error) {
        if (error.code === 4001) {
            alert("Please connect to MetaMask.");
        }
        else {
            handleError(error);
        }
    }
}

// force to show "connect wallet" button
window.disconnectWallet = async function() {
    try {
        if (teavaultWallet.walletConnectLinked()) {
            await teavaultWallet.killSession();
        }
        
        await showContent(true);    
    }
    catch(error) {
        handleError(error);
    }
}

// link wallet connect
window.linkWalletConnect = async function() {
    document.getElementById("linkWallet").disabled = true;
    try {
        const uri = document.getElementById('walletConnectURI').value;
        await teavaultWallet.linkWalletConnect(uri);
    }
    catch(error) {
        document.getElementById("linkWallet").disabled = false;
        handleError(error);
    }
}

// send ETH
window.sendETH = async function() {
    document.getElementById("sendEth").disabled = true;
    try {
        const amount = document.getElementById('ethamount').value;
        const addr = document.getElementById('ethaddr').value;

        const value = ethers.utils.parseEther(amount);

        if (window.confirm("Send " + amount + " to\n" + addr + "?")) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            await signer.sendTransaction({
                to: addr,
                value: value.toString()
            });
        }

        document.getElementById("sendEth").disabled = false;
    }
    catch(error) {
        document.getElementById("sendEth").disabled = false;
        handleError(error);
    }
}

// show erc20 balance
window.showERC20Balance = async function() {
    if (ethers.utils.isAddress(vaultAddress)) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const erc20addr = document.getElementById('erc20addr').value;
            if (!ethers.utils.isAddress(erc20addr)) {
                throw new Error("Invalid ERC20 address");
            }
    
            const erc20 = new ethers.Contract(erc20addr, IERC20.abi, provider);

            // symbol is optional
            let symbol;
            try {
                symbol = await erc20.symbol();
            }
            catch(error) {
                console.log(error);
                symbol = erc20addr;
            }

            // name is optional
            let name;
            try {
                name = await erc20.name();
            }
            catch(error) {
                name = "";
            }

            // decimals is optional
            let decimals;
            try {
                decimals = await erc20.decimals();
            }
            catch(error) {
                decimals = "0";
            }

            const balance = await erc20.balanceOf(vaultAddress);
    
            // produce the message
            const content = '<p>Balance of ' + symbol + ' (' + name + '): ' + ethers.utils.formatUnits(balance, decimals);
            document.getElementById('erc20balance').innerHTML = content;    
        }
        catch(error) {
            handleError(error);
        }
    }
}

// deposit eth
window.depositEth = async function() {
    document.getElementById("depositEth").disabled = true;
    try {
        const amount = document.getElementById('depositethamount').value;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const teavault = new ethers.Contract(teavaultWallet.getVaultAddress(), TeaVaultV2.abi, signer);

        const value = ethers.utils.parseEther(amount);
        if (window.confirm("Deposit " + amount + " ETH?")) {
            await teavault.depositETH(value, { value: value });
        }

        document.getElementById("depositEth").disabled = false;
    }
    catch(error) {
        document.getElementById("depositEth").disabled = false;
        handleError(error);
    }
}

// withdraw eth
window.withdrawEth = async function() {
    document.getElementById("withdrawEth").disabled = true;
    try {
        const amount = document.getElementById('depositethamount').value;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const recipient = await signer.getAddress();
        const teavault = new ethers.Contract(teavaultWallet.getVaultAddress(), TeaVaultV2.abi, signer);

        const value = ethers.utils.parseEther(amount);
        if (window.confirm("Withdraw " + amount + " ETH?")) {
            await teavault.withdrawETH(recipient, value);
        }

        document.getElementById("withdrawEth").disabled = false;
    }
    catch(error) {
        document.getElementById("withdrawEth").disabled = false;
        handleError(error);
    }
}


// approve erc20
window.approveErc20 = async function() {
    document.getElementById("approveErc20").disabled = true;
    try {
        const erc20addr = document.getElementById('depositerc20addr').value;
        const amount = document.getElementById('depositerc20amount').value;
        if (!ethers.utils.isAddress(erc20addr)) {
            throw new Error("Invalid ERC20 address");
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const erc20 = new ethers.Contract(erc20addr, IERC20.abi, signer);

        // decimals is optional
        let decimals;
        try {
            decimals = await erc20.decimals();
        }
        catch(error) {
            decimals = "0";
        }

        const value = ethers.utils.parseUnits(amount, decimals);

        if (window.confirm("Approve " + amount + "?")) {
            await erc20.approve(teavaultWallet.getVaultAddress(), value);
        }

        document.getElementById("approveErc20").disabled = false;
    }
    catch(error) {
        document.getElementById("approveErc20").disabled = false;
        handleError(error);
    }
}


// deposit erc20
window.depositErc20 = async function() {
    document.getElementById("depositErc20").disabled = true;
    try {
        const erc20addr = document.getElementById('depositerc20addr').value;
        const amount = document.getElementById('depositerc20amount').value;
        if (!ethers.utils.isAddress(erc20addr)) {
            throw new Error("Invalid ERC20 address");
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const erc20 = new ethers.Contract(erc20addr, IERC20.abi, signer);
        const teavault = new ethers.Contract(teavaultWallet.getVaultAddress(), TeaVaultV2.abi, signer);


        // decimals is optional
        let decimals;
        try {
            decimals = await erc20.decimals();
        }
        catch(error) {
            decimals = "0";
        }

        const value = ethers.utils.parseUnits(amount, decimals);

        if (window.confirm("Deposit " + amount + "?")) {
            await teavault.deposit(erc20addr, value);
        }

        document.getElementById("depositErc20").disabled = false;
    }
    catch(error) {
        document.getElementById("depositErc20").disabled = false;
        handleError(error);
    }
}

// withdraw erc20
window.withdrawErc20 = async function() {
    document.getElementById("withdrawErc20").disabled = true;
    try {
        const erc20addr = document.getElementById('depositerc20addr').value;
        const amount = document.getElementById('depositerc20amount').value;
        if (!ethers.utils.isAddress(erc20addr)) {
            throw new Error("Invalid ERC20 address");
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const recipient = await signer.getAddress();
        const erc20 = new ethers.Contract(erc20addr, IERC20.abi, signer);
        const teavault = new ethers.Contract(teavaultWallet.getVaultAddress(), TeaVaultV2.abi, signer);

        // decimals is optional
        let decimals;
        try {
            decimals = await erc20.decimals();
        }
        catch(error) {
            decimals = "0";
        }

        const value = ethers.utils.parseUnits(amount, decimals);

        if (window.confirm("Deposit " + amount + "?")) {
            await teavault.withdraw(recipient, erc20addr, value);
        }

        document.getElementById("withdrawErc20").disabled = false;
    }
    catch(error) {
        document.getElementById("withdrawErc20").disabled = false;
        handleError(error);
    }
}

async function showContent(showDisconnected = false) {
    // check if metamask is installed
    if (typeof window.ethereum == 'undefined') {
        showInstallMetaMask();
        return;
    }

    // force to show connect wallet button
    if (showDisconnected) {
        showConnectWallet();
        return;
    }

    // check if wallet is connected
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length == 0) {
        showConnectWallet();
        return;
    }

    // initialize teavault wallet
    // do not initialize more than once to avoid duplicated connections
    if (teavaultWallet === null) {
        vaultAddress = getVaultAddress();
        if (!ethers.utils.isAddress(vaultAddress)) {
            showNoVaultAddress();
            return;
        }

        teavaultWallet = new TeaVaultV2Wallet(vaultAddress, window.ethereum, callback);    
    }

    await showWalletInfo();
}

async function main() {
    // register for events
    if (window.ethereum !== undefined) {
        ethereum.on('accountsChanged', async function(accounts) {
            await showContent();
        });
    
        ethereum.on('chainChanged', async function(accounts) {
            if (teavaultWallet !== undefined && teavaultWallet !== null && teavaultWallet.walletConnectLinked()) {
                await teavaultWallet.updateSession();
            }
    
            await showContent();
        });    
    }

    await showContent();
}

function handleError(error) {
    console.log(error);
    if (typeof error.message === 'string') {
        alert(error.message);
    }
    else {
        alert(error);
    }
}

main().catch((error) => {
    console.log(error);
    handleError(error);
});
