// teavaultv2wallet library
// Teahouse Finance


import { ethers } from 'ethers';
import { Core } from '@walletconnect/core'
import { Web3Wallet } from '@walletconnect/web3wallet'
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils";
import TeaVaultV2 from "./abi/TeaVaultV2.json";

const WCProjectID = 'a6cbc1d0919112116df77e68caf54b93';
const WCMetaData = {
    name: 'TeaVaultV2Wallet',
    description: 'TeaVaultV2 Wallet',
    url: 'tvw.teahouse.finance',
    icons: []
};

export class TeaVaultV2Wallet {

    constructor(vaultAddress, ethProvider, callback) {
        if (typeof vaultAddress !== 'string' || !ethers.utils.isAddress(vaultAddress)) {
            throw "Invalid vaultAddress";
        }

        if (typeof ethProvider !== 'object') {
            throw "Invalid ethProvider";
        }

        if (typeof callback !== 'function') {
            throw "Invalid callback";
        }

        // init provider
        this.vaultAddress = vaultAddress;
        this.ethProvider = ethProvider;
        this.callback = callback;
        this.session = null;

        this.wccore = new Core({ projectId: WCProjectID });
        this.web3wallet = null;
    }

    getVaultAddress() {
        return this.vaultAddress;
    }

    async checkVault() {
        const provider = new ethers.providers.Web3Provider(this.ethProvider);
        const code = await provider.getCode(this.vaultAddress);
        if (code == '0x') {
            return false;
        }
        // if (code !== TeaVaultV2.deployedBytecode) {
        //     return false;
        // }

        return true;
    }

    async getVaultConfig() {
        const provider = new ethers.providers.Web3Provider(this.ethProvider);
        const code = await provider.getCode(this.vaultAddress);
        if (code == '0x') {
            throw "vaultAddress is not a contract";
        }
        // if (code !== TeaVaultV2.deployedBytecode) {
        //     throw "vaultAddress is not a TeaVaultV2 contract";
        // }

        const teavault = new ethers.Contract(this.vaultAddress, TeaVaultV2.abi, provider);
        const config = await teavault.config();

        // sanitize output
        return {
            investor: config.investor,
            manager: config.manager,
            allowManagerSignature: config.allowManagerSignature            
        };
    }

    walletConnectLinked() {
        return this.web3wallet !== null && this.session !== null;
    }

    hasSession() {
        return this.#getCachedSession() !== null;
    }

    #getCachedSession() {
        const local = localStorage ? localStorage.getItem("wc2session") : null;
      
        let session = null;
        if (local) {
            try {
                session = JSON.parse(local);
            }
            catch (error) {
                throw error;
            }
        }

        return session;
    }

    async linkWalletConnect(uri = '') {
        if (this.web3wallet == null) {
            // init web3wallet if not initialized
            this.web3wallet = await Web3Wallet.init({
                core: this.wccore,
                metadata: WCMetaData,
            });

            this.web3wallet.on('session_proposal', async proposal => {
                console.log('session_proposal', proposal);
    
                const provider = new ethers.providers.Web3Provider(this.ethProvider);
                const network = await provider.getNetwork();
        
                const namespaces = await this.#generateNamespaces(proposal.params, network);
                const approvedNamespaces = buildApprovedNamespaces(namespaces);
                this.session = await this.web3wallet.approveSession({
                    id: proposal.id,
                    namespaces: approvedNamespaces,
                });

                if (localStorage) {
                    const obj = { topic: this.session.topic };
                    localStorage.setItem("wc2session", JSON.stringify(obj));
                }

                console.log('session:', this.session);

                // emit chain changed event
                await this.web3wallet.emitSessionEvent({
                    topic: this.session.topic,
                    event: {
                      name: 'chainChanged',
                      //data: [this.vaultAddress]
                      data: network.chainId,
                    },
                    chainId: 'eip155:' + network.chainId
                });

                await this.callback({
                    event: 'session_connected'
                });
            });

            this.web3wallet.on('session_delete', async session => {
                console.log('session_delete:', session);
                if (session.topic == this.session.topic) {
                    this.session = null;
                
                    await this.callback({
                        event: 'session_disconnected'
                    });    
                }
            });

            this.web3wallet.on('session_request', async event => {
                const provider = new ethers.providers.Web3Provider(this.ethProvider);                
                const network = await provider.getNetwork();
                console.log("session_request:", event);
                if (event.topic == this.session.topic) {
                    // Handle Session Request
                    if (event.params.chainId != 'eip155:' + network.chainId) {
                        console.log("Different chainId");
                        return;
                    }

                    await this.#handleCallRequest(event);
                }
            });
        }

        if (uri == '') {
            // try to reconnect current pairing
            const session = this.#getCachedSession();
            try {
                const sessions = this.web3wallet.getActiveSessions();
                this.session = sessions[session.topic];
                console.log("session:", this.session);
            }
            catch(error) {
                console.log(error);
                // ignore error
            }
        }
        else {
            // kill current session if exists
            try {
                const session = this.#getCachedSession();
                if (session != null) {
                    await this.web3wallet.disconnectSession({
                        topic: session.topic,
                        reason: getSdkError("USER_DISCONNECTED")
                    });    
                }
            }
            catch(error) {
                console.log(error);
                // ignore any error
            }

            console.log("pair:", uri);
            await this.web3wallet.core.pairing.pair({ uri });
        }
    }

    async updateSession(newVaultAddress = '') {
        if (newVaultAddress !== '') {
            this.vaultAddress = newVaultAddress;
        }

        const provider = new ethers.providers.Web3Provider(this.ethProvider);
        const network = await provider.getNetwork();

        const namespaces = await this.#generateNamespaces(this.session, network);
        const approvedNamespaces = buildApprovedNamespaces(namespaces);        
        await this.web3wallet.updateSession({
            topic: this.session.topic,
            namespaces: approvedNamespaces,
        });

        // emit chain changed event
        await this.web3wallet.emitSessionEvent({
            topic: this.session.topic,
            event: {
                name: 'chainChanged',
                //data: [this.vaultAddress]
                data: network.chainId,
            },
            chainId: 'eip155:' + network.chainId
        });        
    }

    async killSession() {
        // kill session
        try {
            await this.web3wallet.disconnectSession({
                topic: this.session.topic,
                reason: getSdkError("USER_DISCONNECTED")
            });
        }
        catch(error) {
            console.log(error);
        }

        // remove session object, even if killSession failed
        if (localStorage) {
            localStorage.removeItem("wc2session");
        }

        this.session = null;
    }

    async #generateNamespaces(params, network) {
        const chainId = network.chainId;

        // check if chainId is in required namespaces
        let chains;
        if (params.requiredNamespaces.eip155 != undefined) {
            chains = params.requiredNamespaces.eip155.chains;
        }
        else {
            chains = params.optionalNamespaces.eip155.chains;
        }

        let found = false;
        for (let i = 0; i < chains.length; i++) {
            if (chains[i] == 'eip155:' + chainId) {
                found = true;
                break;
            }
        }

        let namespaces;
        const supportedMethods = [
            "eth_sendTransaction",
            "eth_signTransaction",
            "eth_sign",
            "personal_sign",
            "eth_signTypedData_v4",
            "personal_ecRecover",
            "eth_getCode",
            "wallet_switchEthereumChain",
            "wallet_addEthereumChain"
        ];
        const supportedEvents = [
            "chainChanged",
            "accountsChanged",
        ];

        let eipChains = [];
        let eipAccounts = [];

        for (let i = 0; i < chains.length; i++) {
            const chainId = chains[i].substring(7);
            eipChains.push("eip155:" + chainId);
            eipAccounts.push("eip155:" + chainId + ":" + this.vaultAddress);
        }

        if (!found) {
            // add required namespace
            eipChains.push("eip155:" + chainId);
            eipAccounts.push("eip155:" + chainId + ":" + this.vaultAddress);
        }

        namespaces = {
            proposal: params,
            supportedNamespaces: {
                eip155: {
                    chains: eipChains,
                    methods: supportedMethods,
                    events: supportedEvents,
                    accounts: eipAccounts
                },
            }
        };
        console.log(namespaces);

        return namespaces;
    }

    async #handleCallRequest(event) {
        // avoid duplicate calls with the same id
        if (this.previousPayloadId === event.id) {
            return;
        }

        this.previousPayloadId = event.id;

        switch(event.params.request.method) {
            case 'personal_sign':
                await this.#handlePersonalSign(event);
                break;

            case 'eth_sign':
                await this.#handleMessageSign(event);
                break;

            case 'eth_signTypedData':
                await this.#handleSignTypedData(event);
                break;

            case 'eth_signTransaction':
            case 'eth_sendTransaction':
                await this.#handleTransaction(event);
                break;

            case 'wallet_addEthereumChain':
            case 'wallet_switchEthereumChain':
            case 'personal_ecRecover':
            case 'eth_getCode':        
                await this.#handleMetaMaskAPI(event.params.request);
                break;

            default:
                await this.#handleError(event.id, 'Unsupported operation');
                break;
        }
    }

    async #handlePersonalSign(event) {
        const payload = event.params.request;

        let result;
        try {
            const provider = new ethers.providers.Web3Provider(this.ethProvider);
            const teavault = new ethers.Contract(this.vaultAddress, TeaVaultV2.abi, provider);
            const config = await teavault.config();
            if (!config.allowManagerSignature) {
                throw new Error("Manager signature is not allowed");
            }

            const params = [
                payload.params[0],
                config.manager,
            ];

            await this.callback({
                event: 'eth_call',
                method: payload.method,
                params: params
            });            

            result = await this.ethProvider.request({
                method: payload.method,
                params: params,
                from: config.manager
            });
        }
        catch(error) {
            await this.#handleError(event.id, error);
            return;
        }

        await this.web3wallet.respondSessionRequest({
            topic: this.session.topic,
            response: {
                id: event.id,
                jsonrpc: "2.0",
                result: result,
            }
        });
    }

    async #handleError(id, error) {
        try {
            await this.web3wallet.respondSessionRequest({
                topic: this.session.topic,
                response: {
                    id,
                    jsonrpc: '2.0',
                    error: {
                        code: 5000,
                        message: error.message == undefined ? error : error.message
                    }
                }
            });    
        }
        catch(error) {
            console.log("WC Response error:", error);
        }
        
        await this.callback({
            event: 'error',
            error: error
        });

        return;
    }

    async #handleMessageSign(event) {
        const payload = event.params.request;

        let result;
        try {
            const provider = new ethers.providers.Web3Provider(this.ethProvider);
            const teavault = new ethers.Contract(this.vaultAddress, TeaVaultV2.abi, provider);
            const config = await teavault.config();

            if (!config.allowManagerSignature) {
                throw new Error("Manager signature is not allowed");
            }

            if (payload.params[1].length == 66) {
                const params = [
                    config.manager,
                    payload.params[1],
                ];
    
                await this.callback({
                    event: 'eth_call',
                    method: payload.method,
                    params: params
                });
    
                result = await this.ethProvider.request({
                    method: payload.method,
                    params: params,
                    from: config.manager,
                });    
            }
            else {
                // treat as personal_sign
                const params = [
                    payload.params[1],
                    config.manager,
                ];
    
                await this.callback({
                    event: 'eth_call',
                    method: payload.method,
                    params: params
                });

                result = await this.ethProvider.request({
                    method: "personal_sign",
                    params: [
                        payload.params[1],
                        config.manager,
                    ],
                    from: config.manager,
                });
            }
        }
        catch(error) {
            this.#handleError(event.id, error);
            return;
        }

        await this.web3wallet.respondSessionRequest({
            topic: this.session.topic,
            response: {
                id: event.id,
                jsonrpc: "2.0",
                result: result,
            }
        });
    }

    async #handleSignTypedData(event) {
        const payload = event.params.request;

        let result;
        try {
            const provider = new ethers.providers.Web3Provider(this.ethProvider);
            const teavault = new ethers.Contract(this.vaultAddress, TeaVaultV2.abi, provider);
            const config = await teavault.config();

            if (!config.allowManagerSignature) {
                throw new Error("Manager signature is not allowed");
            }

            const params = [
                config.manager,
                payload.params[1],
            ];

            await this.callback({
                event: 'eth_call',
                method: payload.method,
                params: params
            });

            result = await this.ethProvider.request({
                method: "eth_signTypedData_v4",
                params: params,
                from: config.manager
            });
        }
        catch(error) {
            this.#handleError(event.id, error);
            return;
        }

        await this.web3wallet.respondSessionRequest({
            topic: this.session.topic,
            response: {
                id: event.id,
                jsonrpc: "2.0",
                result: result,
            }
        });
    }

    async #handleTransaction(event) {
        const payload = event.params.request;

        let result;
        try {
            const provider = new ethers.providers.Web3Provider(this.ethProvider);
            const teavault = new ethers.Contract(this.vaultAddress, TeaVaultV2.abi, provider);
            const config = await teavault.config();

            let params = payload.params;

            // check parameters
            if (params[0].data === undefined || params[0].data === '0x') {
                throw new Error("Sending ETH is not supported");
            }

            if (params[0].from.toLowerCase() !== this.vaultAddress.toLowerCase()) {
                throw new Error("Invalid 'from' address");
            }

            // use manager address instead
            params[0].from = config.manager;

            // copy value and set value in params to 0
            const value = params[0].value || '0x0';
            if (params[0].value !== undefined) {
                params[0].value = '0x0';
            }

            // copy to and set to teavault
            const to = params[0].to;
            params[0].to = this.vaultAddress;

            // copy data and set data to managerCall
            const data = params[0].data;
            params[0].data = teavault.interface.encodeFunctionData("managerCall", [ to, value, data ]);

            await this.callback({
                event: 'eth_call',
                method: payload.method,
                params: params
            });

            if (payload.method == 'eth_sendTransaction') {
                // try with callStatic and see if there's a problem
                await this.callback({
                    event: 'eth_call',
                    method: 'eth_callStatic',
                    params: {
                        to: to,
                        value: value,
                        data: data
                    }
                });

                try {       
                    await teavault.callStatic.managerCall(to, value, data, { from: config.manager });
                }
                catch(error) {
                    // convert error message to a more user friendly version
                    if (error.code == "CALL_EXCEPTION") {
                        if (error.error != undefined && error.error.data != undefined && error.error.data.message != undefined) {
                            let err = new Error("Calling " + to + ": Transaction failed with message " + error.error.data.message, { cause: error });
                            err.to = to;
                            err.value = value;
                            err.data = data;
                            throw err;
                        }
                        else if (error.errorName != undefined) {
                            if (error.errorName == "Error") {
                                let err = new Error("Calling " + to + ": Transaction failed with error " + error.reason, { cause: error });
                                err.to = to;
                                err.value = value;
                                err.data = data;
                                throw err;    
                            }
                            else {
                                let err = new Error("Calling " + to + ": Transaction failed with error " + error.errorName, { cause: error });
                                err.to = to;
                                err.value = value;
                                err.data = data;
                                throw err;    
                            }
                        }
                    }

                    throw error;
                }
            }

            // re-estimate gas
            //const gas = await teavault.estimateGas.managerCall(to, value, data, { from: config.manager });
            //params[0].gas = gas.toHexString();

            // let the connected wallet to estimate gas
            delete params[0].gas;

            // let the connected wallet to determine nonce
            delete params[0].nonce;

            // console.log("transaction:");
            // console.log(params);
            //result = await teavault.managerCall(to, value, data, { from: config.manager });
            result = await this.ethProvider.request({
                method: payload.method,
                params: params,
                from: config.manager
            });
        }
        catch(error) {
            this.#handleError(event.id, error);
            return;
        }

        await this.web3wallet.respondSessionRequest({
            topic: this.session.topic,
            response: {
                id: event.id,
                jsonrpc: "2.0",
                result: result,
            }
        });

        await this.callback({
            event: 'result',
            method: payload.method,
            results: result
        });    
    }

    async #handleMetaMaskAPI(payload) {
        try {
            await ethereum.request(payload);
        }
        catch(error) {
            this.#handleError(payload.id, error);
            return;
        }
    }
}
